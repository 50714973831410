/**
 * @module App
 * @description Our NextJS App
 */
import '@module-federation/nextjs-mf/src/include-defaults';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { setMonarchMonitoring } from '@red-digital/bricks';
import { withContentProvider } from '@/providers/content-provider';
import { withFuel } from '@/providers/fuel-provider';
import { ready } from '@/js/utils/events';
import listOfPreampAssets from '../preamp/listOfPreampAssets';
import { useCohesionEvents } from '@/hooks/useCohesionEvents';
import { CohesionSnippet } from '@redventures/cohesion-utils-react';
import { cohesionSnippet } from '@/js/config/cohesion';
import { withCohesion } from '@/providers/cohesion-provider';

import '@/fonts/icomoon/style.css';
import '@/styles/globals.css';
import '@/styles/bricks/all.scss';

/**
 * @function App
 * @description Our NextJS App
 */
const App = ( { Component, pageProps } ) => {
  const router = useRouter();

  useCohesionEvents();

  /**
   * @function loadJS
   * @description Load our global js
   */
  const loadJS = () => import( '@/js/main.js' ).then( ( module ) => module.default() );

  /**
   * @function useEffect
   * @description Tagular Page View, Replace Fuse numbers
   */
  useEffect( () => {
    router.events.on( 'routeChangeComplete', () => {
      tagular( 'pageView' );

      if ( window && window.newrelic ) {
        window.newrelic.interaction().save();
      }
    } );
  }, [ router.events ] );

  /**
   * @function useEffect
   * @description Load a11y conditional
   */
  useEffect( () => {
    if ( process.env.environment === 'local' || 'a11y' in router.query ) {
      import( '@khanacademy/tota11y' );
    }
  }, [ router.query ] );

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    setMonarchMonitoring( { hasUpdatedSnippet: true } );
    ready( loadJS );
  }, [ ] );

  return (
    <>

      <CohesionSnippet config={ cohesionSnippet } src="https://cdn.cohesionapps.com/cohesion/cohesion-latest.min.js" />
      <Component { ...pageProps } />
    </>
  );
};

/**
 * @function reportWebVitals
 * @description Report web vitals, send them off to analytics
 */
export const reportWebVitals = ( metric ) => {
  if ( process.env.environment !== 'production' ) {
    return;
  }

  if ( metric.label === 'web-vital' ) {
    import( '@/js/plugins/cwv.js' ).then( ( module ) => module.default( metric ) );
  }
};

const appHOC1 = withContentProvider( { App } );
const appHOC2 = withFuel( { App: appHOC1 } );
const appHOC3 = withCohesion( { App: appHOC2, assets: listOfPreampAssets } );

export default appHOC3;
