import {
  WRITE_KEY, SOURCE_KEY, MONARCH_SOURCE, MONARCH_TOKEN
} from '@/js/constants/cohesion';

export const cohesionSnippet = {
  tagular: {
    apiKey: '2646f3b4-2a12-48ac-979d-ad332d8e53a6.be7259f3-a108-4532-b2d6-cabba9185e07',
    writeKey: WRITE_KEY,
    sourceKey: SOURCE_KEY,
    multiparty: true,
    taggy: { enabled: true }
  },
  fuse: {
    leases: [ { pool: 'YMRmKnyGqunDD2ZVVahkUY' } ],
    metadata: {
      vdn: '68231'
    }
  },
  monarch: {
    sourceId: MONARCH_SOURCE,
    token: MONARCH_TOKEN,
    autoRequest: {
      enabled: true,
      suppressPlacements: false
    }
  },
  consent: {
    required: false
  }
};
